import React from 'react';
import '../css/home.css'

import Header from '../compontents/Header';
import Card from '../compontents/Card';

const imgStyle = { objectFit: 'cover', width: '100%', height: '100%' }

class Home extends React.Component {

  render() {
    return (
      <div className="home">
        <Header history={this.props.history}></Header>
        <div style={{ height: 380, width: '100%',position:'relative' }}>
          <img src={require('../assets/image/banner/AI入门banner.jpg')} style={imgStyle} alt='' />
          {/* <div style={{position:'absolute',top:"calc(50% - 100px)",width:'100%',textAlign:'center',color:'#fff',padding:'0 20px'}}>
            <h1 style={{fontSize:'4rem',color:'#fff'}}>A.I. 入門</h1>
            <p style={{fontSize:'2.164rem'}}>通過直觀易懂的線上 Demo，瞭解 A.I. 領域的前沿知識</p>
          </div> */}
        </div>
        <Card data={this.props.data}  history={this.props.history}></Card>
      </div>
    );
  }
}

export default Home;
