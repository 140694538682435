import React from 'react';
import '../css/index.css'

import Header from '../compontents/Header';
import Demo from '../compontents/demo';
import Introduction from '../compontents/Introduction'
import Resources from '../compontents/Resources'
import Knowledge from '../compontents/Knowledge'


const demoStyle = { padding: 10, fontSize: 18, marginBottom: '1em', lineHeight: '1.5em' }
class Details extends React.Component {




  render() {
    const { data, history } = this.props
    let num = Number(history.location.search.slice(1))
    let datas = data && data.data.length > 0 && data.data[num]
    return (
      <div className="details" style={{ marginBottom: 80,paddingBottom: 100 }}>
        <Header history={this.props.history}></Header>
        <h1 style={{ textAlign: 'center', fontSize: '4.2rem', margin: '2.1rem 0 1.68rem 0' }}>{datas.title_name}</h1>
        <div>
          <Demo data={datas} />
        </div>
        <div className="container keys" style={{ padding: '30px 10px' }}>
          {datas.seach_key.map(x => {
            return (
              <span key={`${x.value}-${x.id}`}>{x.value}</span>
            )
          })}
        </div>
        {datas.is_dec && <div className="container">
          <h1 style={{ fontSize: '1.5rem', fontWeight: 600 }}>描述</h1>
          <p style={demoStyle}>{datas.dec}</p>
        </div>}
        <Introduction data={datas} />
        {datas.isKnowledge && <Knowledge data={datas} />}
        {datas.isResources && <Resources data={datas} />}
      </div>
    );
  }
}

export default Details;
