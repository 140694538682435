import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import { Button, Modal } from 'antd'

import data from './data/data.json'
import dataDetails from './data/details.json'

import Home from "./container/Home";
import Details from './container/Details';
import Speech from './container/speech';
import Text from './container/Text';
import Vision from './container/Visoion';
import Art from './container/Art'

class App extends React.Component {

    state = {
        visible: false,
    }

    componentDidMount = () => {

        if (window.location.href.split("://")[0] === "http" && window.location.href.indexOf('localhost') === -1) {
            this.setState({
                visible: true
            })
        }
    }

    handleOk = () => {
        this.setState({
            visible: false
        }, () => {
            window.location.href = "https://" + window.location.href.split("://")[1];
        })

    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        let datas = data.data;
        return (
            <Router basename="/" >
                <div className="App" >
                    <Switch />
                        <Route exact path="/"
                            render={
                                ({ match, history, location }) => (< Home history={history}
                                    location={location}
                                    match={match}
                                    data={datas.ai}
                                />)} />
                        <Route exact path="/home"
                            render={
                                ({ match, history, location }) => (< Home history={history}
                                    location={location}
                                    match={match}
                                    data={datas.ai}
                                />)} />
                        <Route path="/speech"
                            render={
                                ({ match, history, location }) => (< Speech history={history}
                                    location={location}
                                    match={match}
                                    data={datas.speech}
                                />)} />
                        <Route path="/text"
                            render={
                                ({ match, history, location }) => (< Text history={history}
                                    location={location}
                                    match={match}
                                    data={datas.text}
                                />)} />
                        <Route path="/vision"
                            render={
                                ({ match, history, location }) => (< Vision history={history}
                                    location={location}
                                    match={match}
                                    data={datas.vision}
                                />)} />
                        <Route path="/art"
                            render={
                                ({ match, history, location }) => (< Art history={history}
                                    location={location}
                                    match={match}
                                    data={datas.art}
                                />)} />
                        <Route path="/homes/details"
                            render={
                                ({ match, history, location }) => (< Details history={history}
                                    location={location}
                                    match={match}
                                    data={dataDetails.ai}
                                />)} />
                        <Route path="/speechs/details"
                            render={
                                ({ match, history, location }) => (< Details history={history}
                                    location={location}
                                    match={match}
                                    data={dataDetails.speech}
                                />)} />
                        <Route path="/texts/details"
                            render={
                                ({ match, history, location }) => (< Details history={history}
                                    location={location}
                                    match={match}
                                    data={dataDetails.text}
                                />)} />
                        <Route path="/visions/details"
                            render={
                                ({ match, history, location }) => (< Details history={history}
                                    location={location}
                                    match={match}
                                    data={dataDetails.vision}
                                />)} />
                        <Route path="/arts/details"
                            render={
                                ({ match, history, location }) => (< Details history={history}
                                    location={location}
                                    match={match}
                                    data={dataDetails.art}
                                />)} />
                        <Modal title="是否跳转到HTTPS"
                            closable={false}
                            visible={this.state.visible}
                            footer={
                                [
                                    // 定义右下角 按钮的地方 可根据需要使用 一个或者 2个按钮
                                    <Button key="back"
                                        onClick={this.handleCancel} > </Button>, <Button key="submit"
                                            type="primary"
                                            onClick={this.handleOk} >
                                        确认 </Button>,]}>
                            体验中心的多数应用必须在 HTTPS 环境下才能正常使用， 请点击「 确认」 按钮切换到 HTTPS 环境下进行浏览。
                        </Modal>
                </div>
            </Router>
        );
    }

}

export default App;