import React from 'react';
import '../css/index.css'

class Iframe extends React.Component {

    state = {
        show: false
    }

    componentDidMount = () => {
        let _this = this
        let iframe = document.getElementById(this.props.data.src)
        if (iframe) {
            iframe.onload = (e) => {
                console.log(e)
                setTimeout(() => {
                    _this.setState({
                        show: true
                    })
                }, 1000);
            }
        }
        // window.addEventListener("message", e => {
        //     if (e.data === "none") {
        //         this.setState({
        //             show: true
        //         })
        //     }
        // })
    }

    render() {
        const { data } = this.props
        const { show } = this.state
        return (
            <div className="iframe"
                id="iframes" >
                <iframe id={data.src} title={data.src} frameBorder="0" src={data.src} style={data.iframeCss ? data.iframeCss : { height: data.height }}
                    scrolling={data.iframeScroll ? "no" : "yes"}
                    allow="camera; microphone" > </iframe>
                {!show && < div style={{ position: 'relative', width: '100%', height: '100%', background: '#fff' }}>
                    <img style={{ position: 'absolute', top: '45%' }} src={require('../assets/image/preloader.gif')} alt='' /> </div >
                } </div>
        );
    }
}

export default Iframe;