import React from 'react';
import '../css/home.css'

import Header from '../compontents/Header';
import Card from '../compontents/Card';

const imgStyle = { objectFit: 'cover', width: '100%', height: '100%' }

class Vision extends React.Component {

    render() {

        return (
            <div className="vision">
                <Header history={this.props.history}></Header>
                <div style={{ height: 380, width: '100%', position: 'relative' }}>
                    <img src={require('../assets/image/banner/AI视觉banner.jpg')} style={imgStyle} alt='' />
                    {/* <div style={{ position: 'absolute', top: "calc(50% - 100px)", width: '100%', textAlign: 'center', color: '#fff', padding: '0 20px' }}>
                        <h1 style={{ fontSize: '4rem', color: '#fff' }}>計算機視覺</h1>
                        <p style={{ fontSize: '2.164rem' }}>讓電腦“感知”人類能看到的影像，令AI能根據視頻、圖像等做出決策。</p>
                    </div> */}
                </div>
                <Card data={this.props.data} history={this.props.history} ></Card>
            </div>
        );
    }
}

export default Vision;
